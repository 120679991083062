import React from "react";
import { AlertCircle, ShoppingCart, Zap, Twitter } from 'lucide-react';
import logo from "./assets/appicon.png";

function App() {
  const handlePurchase = (trackerId) => {
    // Redirect to the backend handler
    window.location.href = `/payments/new?t_id=${trackerId}`;
  };

  return (
      <div className="min-h-screen bg-gradient-to-b from-purple-950 to-black text-purple-100">
        <header className="bg-purple-990 shadow-md">
          <div className="container mx-auto px-4 py-6 flex justify-between items-center">
            <div className="flex items-center">
              <img src={logo} alt="Jupara Logo" className="h-12 w-auto mr-3"/>
              <h1 className="text-3xl font-bold text-purple-100">Jupara Freebies</h1>
            </div>
            <nav>
              <ul className="flex space-x-4">
                <li><a href="#features" className="text-purple-200 hover:text-white">Features</a></li>
                <li><a href="#pricing" className="text-purple-200 hover:text-white">Pricing</a></li>
                <li><a href="#contact" className="text-purple-200 hover:text-white">Contact</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <main className="container mx-auto px-4 py-12">
          <section className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4 text-purple-100">Get Unbeatable Deals Automated</h2>
            <p className="text-xl text-purple-200 mb-8">Jupara finds and orders heavily discounted products for you, saving you time and money.</p>
            <button className="bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300">Start Saving Now</button>
          </section>

          <section id="features" className="mb-16">
            <h3 className="text-3xl font-bold mb-8 text-center text-purple-100">How It Works</h3>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <ShoppingCart className="w-12 h-12 text-blue-300 mb-4" />
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Automated Orders</h4>
                <p className="text-purple-200">Our AI handles the entire order process from start to finish so you don't have to lift a finger.</p>
              </div>
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <Zap className="w-12 h-12 text-blue-300 mb-4" />
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Instant Discounts</h4>
                <p className="text-purple-200">Get access to exclusive discounts and flash sales before they're gone.</p>
              </div>
              <div className="bg-purple-800 p-6 rounded-lg shadow-md">
                <AlertCircle className="w-12 h-12 text-blue-300 mb-4" />
                <h4 className="text-xl font-semibold mb-2 text-purple-100">Smart Alerts</h4>
                <p className="text-purple-200">Receive notifications throughout the order process and live updates from our AI.</p>
              </div>
            </div>
          </section>

          <section id="pricing" className="mb-16">
            <h3 className="text-3xl font-bold mb-8 text-center text-purple-200">Choose Your Beta Plan</h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-purple-800 p-8 rounded-lg shadow-md border border-purple-600">
                <h4 className="text-2xl font-semibold mb-4 text-purple-200">Basic Membership</h4>
                <p className="text-4xl font-bold mb-4 text-purple-300">$55<span
                    className="text-xl font-normal text-purple-400">/month</span></p>
                <ul className="mb-8 text-purple-300">
                  <li className="mb-2">✓ Automated order placement</li>
                  <li className="mb-2">✓ Access to daily deals</li>
                  <li className="mb-2">✓ Basic alert system</li>
                  <li className="mb-2">✓ Product restock module</li>
                </ul>
                <button
                    onClick={() => handlePurchase('b78b77d8-d399-4964-a54b-9eee2d534a04')}
                    className="w-full bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
                >
                  Purchase
                </button>
              </div>
              <div className="bg-purple-700 p-8 rounded-lg shadow-md border border-purple-500">
                <h4 className="text-2xl font-semibold mb-4 text-purple-100">Premium Membership</h4>
                <p className="text-4xl font-bold mb-4 text-purple-200">$80<span
                    className="text-xl font-normal text-purple-300">/month</span></p>
                <ul className="mb-8 text-purple-200">
                  <li className="mb-2">✓ All Basic features</li>
                  <li className="mb-2">✓ Priority access to limited-time offers</li>
                  <li className="mb-2">✓ Higher Concurrent Account Limit</li>
                  <li className="mb-2">✓ Advanced AI-powered modules</li>
                </ul>
                <button
                    onClick={() => handlePurchase('59ac6f2b-682a-44a4-a4bf-c1149392fd76')}
                    className="w-full bg-purple-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-400 transition duration-300"
                >
                  Purchase
                </button>
              </div>
            </div>
          </section>

          <section id="contact" className="text-center">
            <h3 className="text-3xl font-bold mb-4 text-purple-200">Want to Learn More?</h3>
            <p className="text-xl text-purple-300 mb-8">Follow us on Twitter for the latest updates and deal alerts!</p>
            <a
                href="https://twitter.com/JuparaFreebies"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition duration-300"
            >
              <Twitter className="mr-2" size={24}/>
              Follow us on Twitter
            </a>
          </section>
        </main>

        <footer className="bg-black text-purple-300 py-8">
          <div className="container mx-auto px-4 text-center">
            <p>&copy; 2024 Jupara. All rights reserved.</p>
          </div>
        </footer>
      </div>
  );
}

export default App;
